<template>
    <section id="contactSection">
        <h2>Get In Touch</h2>
        <p>
            Although, I am always busy working on my next big thing, <br>
            You are always welcome to email me for a <em>job opportunity</em> or to <em>say hi</em>. <br>
            I always try to answer in a timely fashion.
        </p>
        <br>
        <div>
            <a href="https://www.linkedin.com/in/robert-binkowski-9bb565195/" class="button" target="_blank">LinkedIn</a>
            <a href="mailto:binkowski95@gmail.com" class="button" target="_blank">Email</a>
        </div>
    </section>
</template>

<style lang="scss">
@import "../assets/scss/variables";
#contactSection{
    div{
        padding: 1em;
    }
}
</style>